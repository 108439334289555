:root {
  --backgroud-color: #fff;
  --board-color: #f2f2f3;
  --card-color: #fff;
  --text-color: #000;
  --drop-down-color: #fff;
  --card-box-shadow: #e2dede;
  --add-btn-hover: #000;
  --modal-color:#fff;
  --task-hover:#091e4214;
}
[data-theme="dark"] {
  --background-color: #212121;
  --board-color: #383838;
  --card-color: #212121;
  --card-box-shadow: #2a2a2a;
  --drop-down-color: #383838;
  --text-color: #fff;
  --add-btn-hover: #fff;
  --modal-color:#383838;
  --task-hover:#212121;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
