.tag {
  background-color: #d7e1fe;
  width: fit-content;
  padding-inline: 0.5em;
  border-radius: 5px 5px 5px 5px;
  margin-top: auto;
  font-size: 13px;
  height: 20px;
  font-weight: 600;
  color: #fff;
}
